import React, { useEffect, useState } from 'react';

import { Modal, Button, Form, Input, Slider, Select, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import { OpportunitySwitch } from 'components/controls/OpportunitySwitch';
import { maxBy, sortBy } from 'lodash';

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24
	}
};

export const RiskEditionModal = (props: any) => {

	console.log('RiskEditionModal', props);

	const { likelihoods = [], impacts = [], riskCategories = [], risks = [], indicators = [], tags = [] } = props;

	const [form] = Form.useForm();
	const { t } = useTranslation();

	const isOpportunity = Form.useWatch('isOpportunity', { form, preserve: true });
	const [areTooltipVisibles, setAreTooltipVisibles] = useState(false);

	let maxLikehood = maxBy(likelihoods, v => parseInt(v.id))?.id || 3
	let maxImpact = maxBy(impacts, v => parseInt(v.id))?.id || 3

	console.log("maxLikehood", maxLikehood)
	console.log("maxImpact", maxImpact)

	useEffect(() => {
		setTimeout(() => {
			setAreTooltipVisibles(true);
		}, 400)
	}, []);

	const handleFormSubmit = () => {
		console.log('handleFormSubmit');
		form.validateFields()
			.then((values) => {
				console.log('validated', values);
				props.onSave(values);
				props.onHide();
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo);
			});
	};

	const footer = (
		<div style={{ display: 'flex' }}>
			{props.onDelete && (
				<Button
					onClick={() => {
						props.onDelete(props);
					}}
					danger={true}
					style={{ marginRight: 'auto' }}
				>
					Delete
				</Button>
			)}
			<Button
				onClick={() => {
					props.onHide();
				}}
				style={{ marginRight: 'auto' }}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	);

	return (
		<Modal
			width={props.width || "70%"}
			open={true}
			title={
				props.id ?
					(props.values?.isOpportunity ? t('models.risk.actions.edit_opportunity') : t('models.risk.actions.edit'))
					:
					t('models.risk.actions.create_new')
			}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
			maskClosable={false}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values);
					props.onHide();
				}}
				initialValues={props.values}
			>

				{!props.id && <Form.Item name="isOpportunity" className="mb-0 ml-auto d-flex" >
					<OpportunitySwitch className="ml-1 mr-1"></OpportunitySwitch>
				</Form.Item>}

				<div className='d-flex w-100'>
					<Form.Item
						name="reference"
						label={t("models.risk.fields.reference")}
						className="mr-1"
						style={{ width: '20%' }}
						rules={[
							{
								max: 5,
								message:
									t("messages.form.max_length_exceeded_error", { length: 5 })
							}
						]}
					>
						<Input
							autoComplete='off'
							count={{
								show: true,
								max: 5,
							}}></Input>
					</Form.Item>
					<Form.Item
						name="name"
						label={t("models.risk.fields.name")}
						required={true}
						className='mr-1'
						style={{ width: '60%' }}
						rules={[
							{
								max: 50,
								message:
									t("messages.form.max_length_exceeded_error", { length: 50 })
							},
							{
								required: true,
								message: t("messages.form.required_error")
							}
						]}
					>
						<Input
							autoComplete='off'
							count={{
								show: true,
								max: 50,
							}}></Input>
					</Form.Item>
					<Form.Item
						name="evaluatedAt"
						label={t("models.risk.fields.evaluated_at")}
						required={false}
						style={{ width: '20%' }}
					>
						<DatePicker
							style={{ zIndex: '2000' }}
							format={'DD/MM/YYYY HH:mm'}
							className='w-100'
							showTime={{
								format: 'HH:mm',
								minuteStep: 15
							}}></DatePicker>
					</Form.Item>
				</div>

				<Form.Item name="description" label={t("models.risk.fields.description")} rules={[
					{
						max: 300,
						message:
							t("messages.form.max_length_exceeded_error", { length: 300 })
					}
				]}>
					<Input.TextArea
						rows={5} count={{
							show: true,
							max: 300,
						}}></Input.TextArea>
				</Form.Item>

				<div className='d-flex w-100'>
					<Form.Item name="riskCategoryId" label={t('models.risk.fields.risk_category')} className="w-50">
						<Select
							placeholder={t('models.risk.fields.risk_category')}
							allowClear
							options={sortBy(riskCategories, "name").map(
								(category: any) => {
									return {
										value: category.id,
										label: category.name
									};
								}
							)}
						/>
					</Form.Item>
					{!isOpportunity &&
						<Form.Item name="matchingOpportunityId" label={t('models.risk.fields.matching_opportunity')} className="w-50 ml-1">
							<Select
								placeholder={t('models.risk.fields.matching_opportunity')}
								options={
									[].concat(
										{
											value: null,
											label: "No opportunity"
										},
										sortBy(risks.filter(r => r.isOpportunity), "name").map(
											(o: any) => {
												return {
													value: o.id,
													label: o.name
												};
											})
									)
								}
							/>
						</Form.Item>
					}

					{isOpportunity &&
						<Form.Item name="risksId" label={t("models.risk.fields.matching_risks")} className="w-50 ml-1">
							<Select
								placeholder={t('models.risk.fields.matching_risks')}
								allowClear
								mode="multiple"
								defaultValue={props.id ? risks.filter(r => r.matchingOpportunityId == props.id).map(r => r.id) : []}
								options={sortBy(risks.filter(r => !r.isOpportunity), "name").map(
									(o: any) => {
										return {
											value: o.id,
											label: o.name
										};
									}
								)}
							/>
						</Form.Item>
					}
				</div>


				<div className='d-flex w-100'>
					<Form.Item name="impact" className="w-50" label={t("models.risk.fields.impact")} style={{
						paddingRight: '120px'
					}}>
						<Slider
							tooltip={{
								placement: 'bottom', open: areTooltipVisibles,
								formatter: (v) => {
									let name = impacts.find(l => l.id == parseInt(v))?.name
									return name ? v + " - " + name : v
								}
							}}
							dots={true}
							step={0.5}
							min={1}
							max={maxImpact}
						/>
					</Form.Item>
					<Form.Item name="likelihood" className="w-50" label={t("models.risk.fields.likelihood")} style={{
						paddingRight: '120px'
					}}>
						<Slider
							tooltip={{
								placement: 'bottom', open: areTooltipVisibles, formatter: (v) => {
									let name = likelihoods.find(l => l.id == parseInt(v))?.name
									return name ? v + " - " + name : v
								}
							}}
							dots={true}
							step={0.5}
							min={1}
							max={maxLikehood}
						/>
					</Form.Item>
				</div>

				<Form.Item name="impactedIndicators" label={t("models.indicator.name_plural")}>
					<Select
						mode="multiple"
						allowClear
						style={{ width: '100%' }}
						placeholder="Please select"
						options={sortBy(indicators, "name")}
						fieldNames={{
							label: "name",
							value: "id"
						}}
					/>
				</Form.Item>

				<Form.Item name="taggings" label="Tags">
					<Select
						mode="multiple"
						allowClear
						style={{ width: '100%' }}
						placeholder="Please select"
						options={sortBy(tags, "name")}
						fieldNames={{
							label: "name",
							value: "id"
						}}
					/>
				</Form.Item>

			</Form>
		</Modal>
	);
};

export default RiskEditionModal;
